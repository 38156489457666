@font-face
  font-family: "AppleChancery"
  src: url("/fonts/Apple-Chancery.eot") /* IE9*/
  src: local('AppleChancery'), url("/fonts/Apple-Chancery.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */ url("/fonts/Apple-Chancery.woff") format("woff"), /* chrome、firefox */ url("/fonts/Apple-Chancery.ttf") format("truetype") /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/

div.sidebar-about h1, h1, h2
  font-family: 'AppleChancery', Garamond, serif


$curi-banner-red: #821414
$curi-italics-red: #A81717 // and visited link
$curi-link-red: #BF4545 // new link
$curi-blockquote-yellow: #FFF6DB

/* http://paletton.com  colors that go with banner red */

$paletton-green: #106810
$paletton-orange: #824714
$paletton-blue: #0D4C4F

$pdf-orange: #c9531d // #DB591F /////// #E05D23 // #e16128
$pdf-blue: #2b6faf
$pdf-green: #62a17b // too light

$light-red: #FF9999
$light-orange: #FFC899
$light-green: #80D580
$light-blue: #68A9AC

$sidebar-color: $curi-banner-red
$italics-color: $pdf-orange
$link-color: $paletton-green
$link-visited-color: $paletton-green
$bold-color: $pdf-blue
$heading-color: $curi-italics-red



$blockquote-color: $light-orange
$blockquote-color-2: $light-blue
$blockquote-color-3: $light-green
$blockquote-color-4: $light-red

.sidebar, footer
  background-color: $sidebar-color
  
div.content
  em, i
    color: $italics-color
    
  b, strong
    color: $bold-color
  
  em strong, strong em
    color: $bold-color
    
  h1,h2,h3,h4,h5,h6
    color: $heading-color
    a, a:visited
      color: $heading-color
      text-decoration: none
    a:hover
      text-decoration: underline

  a
    color: $link-color
    text-decoration: underline
  a:visited
    color: $link-visited-color


body .content #mc_embed_signup
  form
    padding: 0px
  input
    max-width: 400px


#interview .elliot
  color: #b32
  font-weight: bold


#interview .david
  color: #27a
  font-weight: bold



